import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Fiz from '../views/Fiz.vue'
import Serv from '../views/Serv.vue'
import Docs from '../views/Docs.vue'
import Contacts from '../views/Contacts.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/services',
    component: Serv
  },
  {
    path: '/docs',
    component: Docs
  },
  {
    path: '/fizlica',
    component: Fiz
  },
  {
    path: '/urlica',
    component: Fiz
  },
  {
    path: '/contacts',
    component: Contacts
  },
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
