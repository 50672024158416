import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import YmapPlugin from 'vue-yandex-maps'

Vue.config.productionTip = false

const settings = {
  apiKey: '8c4059db-3b8d-4535-a15e-569ee80fc827',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1'
}
Vue.use(YmapPlugin, settings)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
