<template>
    <section id="serv">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Наши услуги</h2>
                </div>
                <div class="col-lg-4 wow fadeIn" v-for="(item, index) in servs" :key="index">
                    <a :href="item.link" :target="!item.target ? '' : '_blank' ">
                        <div class="serv-box">
                                <div class="serv-img" :style="{'background-image': 'url(' + item.img + ')'}"></div>
                                <div class="serv-body">
                                    <h3>{{item.name}}</h3>
                                    <p class="grey-txt">
                                        {{item.descr}}
                                    </p>
                                </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
            servs: [
                {
                    name: 'Газификация',
                    descr: 'Чтобы получить возможность пользоваться газом в своём доме или на предприятии – важно подключиться к газораспределительной сети.',
                    img: require('../assets/img/serv1.png'),
                    link: '/urlica'
                },
                {
                    name: 'Газогенераторы',
                    descr: 'Чтобы получить возможность пользоваться газом в своём доме или на предприятии – важно подключиться к газораспределительной сети.',
                    img: 'http://f1.lpcdn.site/603e4ced3f5ee96a92cc50a8ad73405c/55cd830cbec361d0d4c714008bb5cbd9.jpg',
                    target: true,
                    link: 'http://btsco.ru/'
                },
                {
                    name: 'Котельные',
                    descr: 'Чтобы получить возможность пользоваться газом в своём доме или на предприятии – важно подключиться к газораспределительной сети.',
                    img: require('../assets/img/serv2.png'),
                    link: '/cotelnie'
                },
            ]
        }
    }
}
</script>