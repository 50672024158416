<template>
    <section id="gallery">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Наши объекты</h2>
                </div>

                <div :class="`col-lg-${item.size}`" v-for="(item, index) in gallery" :key="index">
                    <div class="gall-box">
                        <h4>{{item.name}}</h4>
                        <div class="gall-img" :style="{'background-image': 'url(' + item.img+ ')'}"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
            gallery: [
                {
                    name: 'Объект 1',
                    img: 'https://btsgaz.webink.site/wp-content/uploads/2022/03/dsc_8340-1-scaled.jpg',
                    size: 3
                },
                {
                    name: 'Объект 2',
                    img: 'https://btsgaz.webink.site/wp-content/uploads/2022/03/dsc_8359-1-scaled.jpg',
                    size: 6
                },
                {
                    name: 'Объект 3',
                    img: 'https://btsgaz.webink.site/wp-content/uploads/2022/03/dsc_8343-1-scaled.jpg',
                    size: 3
                },
                {
                    name: 'Объект 3',
                    img: 'https://btsgaz.webink.site/wp-content/uploads/2022/03/dsc_8350-1-scaled.jpg',
                    size: 3
                },
                {
                    name: 'Объект 3',
                    img: 'https://btsgaz.webink.site/wp-content/uploads/2022/03/dsc_8375-1-scaled.jpg',
                    size: 3
                },
                {
                    name: 'Объект 2',
                    img: 'https://btsgaz.webink.site/wp-content/uploads/2022/03/dsc_8344-1-scaled.jpg',
                    size: 6
                },
                {
                    name: 'Объект 1',
                    img: 'https://btsgaz.webink.site/wp-content/uploads/2022/03/dsc_8367-1-scaled.jpg',
                    size: 3
                },
                {
                    name: 'Объект 2',
                    img: 'https://btsgaz.webink.site/wp-content/uploads/2022/03/dsc_8386-1-scaled.jpg',
                    size: 6
                },
                {
                    name: 'Объект 3',
                    img: 'https://btsgaz.webink.site/wp-content/uploads/2022/03/dsc_8376-1-scaled.jpg',
                    size: 3
                },
            ]
        }
    }
}
</script>