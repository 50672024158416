<template>
    <nav>
        <div class="container">
            <div class="shapka">
                <ul>
                    <li v-for="(item, index) in links" :key="index" :class="{dropLi : item.children}">
                        <a :href="item.link" v-if="!item.target && !item.children">{{item.txt}}</a>
                        <span v-else-if="item.children">{{item.txt}}</span>
                        <a :href="item.link" target="_blank" v-else>{{item.txt}}</a>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/9/9d/Arrow-down.svg" 
                        v-if="item.children">
                        <ul class="drop" v-if="item.children">
                            <router-link tag="li" :to="child.link" v-for="(child, i) in item.children" :key="i">
                                {{child.txt}}
                            </router-link>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        data() {
            return {
                links: [{
                        txt: 'Газификация',
                        link: '/gazification',
                        target: false,
                        children: [
                            {
                                txt: 'Юр. лица',
                                link: '/urlica',
                            },
                            {
                                txt: 'Физ. лица',
                                link: '/fizlica',
                            },
                            {
                                txt: 'Поселки',
                                link: '/poselki',
                            },
                        ]
                    },
                    {
                        txt: 'Газогенераторы',
                        link: 'http://btsco.ru/',
                        target: true
                    },
                    {
                        txt: 'Котельные',
                        link: '/cotelnie',
                        target: false
                    },
                ]
            }
        }
    } 
</script>