<template>
  <div id="app">

    <Header />
    <Nav />
    
    <router-view></router-view>

    <Footer/>

  </div>
</template>

<script>
import './assets/css/style.css'
import Header from './components/Header.vue'
import Nav from './components/Nav.vue'
import Footer from './components/Footer.vue'

export default {  
    components: {Header, Nav, Footer}
}
</script>
