<template>
    <header>
        <div class="container">
            <div class="shapka">
                <router-link tag="a" to="/">
                    <img src="../assets/img/logo.png" class="logo">
                </router-link>
                <ul class="hidden-xs">
                    <router-link tag="li" :to="item.link" v-for="(item, index) in links" :key="index">
                        {{item.txt}}
                    </router-link>
                </ul>
                <div class="header-cont hidden-xs">
                   <p><a href="tel:88126141317"><span class="mdi mdi-phone"></span>8 (812) 614-13-17</a></p>
                   <p><a href="mailto:ooobts2021@yandex.ru"><span class="mdi mdi-email"></span>ooobts2021@yandex.ru</a></p>
                </div>

                <div class="burger hidden-lg hidden-md" @click="mobileMenu = !mobileMenu" :class="{activeBurger : mobileMenu}">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
        <div class="mobile-nav" :class="{mobileMenu : mobileMenu}">
            <ul>
                <router-link tag="li" :to="item.link" v-for="(item, index) in links" :key="index">
                    {{item.txt}}
                </router-link>
            </ul>
            <p><a href="tel:88126141317"><span class="mdi mdi-phone"></span>8 (812) 614-13-17</a></p>
            <p><a href="mailto:ooobts2021@yandex.ru"><span class="mdi mdi-email"></span>ooobts2021@yandex.ru</a></p>
        </div>
    </header>
</template>

<script>
export default {
    data(){
        return{
            mobileMenu: false,
            links: [
                {txt: 'Главная', link: '/'},
                {txt: 'О компании', link: '/about'},
                {txt: 'Услуги', link: '/services'},
                {txt: 'Документы', link: '/docs'},
                {txt: 'Контакты', link: '/contacts'},
            ]
        }
    }
}
</script>