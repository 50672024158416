<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-xs-6 mb-30">
                    <img src="../assets/img/logo.png" class="logo">
                    <br><br>
                    <p class="black-txt">Газификация коммерческих объектов <br> и частного жилья в <br> Ленинградской области</p>
                </div>
                <div class="col-lg-3 col-xs-6 mb-30">
                    <h5>МЕНЮ</h5>
                    <ul>
                        <router-link tag="li" :to="item.link" v-for="(item, index) in links" :key="index">
                            {{item.txt}}
                        </router-link>
                    </ul>
                </div>
                <div class="col-lg-3 col-xs-6 mb-30">
                    <h5>УСЛУГИ</h5>
                    <ul>
                        <li v-for="(item, index) in servs" :key="index">
                            <a :href="item.link" target="_blank" v-if="item.target">{{item.txt}}</a>
                            <a :href="item.link" v-else>{{item.txt}}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3 col-xs-6 mb-30">
                    <h5>КОНТАКТЫ</h5>

                    <p class="black-link"><a href="tel:88126141317"><span class="mdi mdi-phone"></span>8 (812) 614-13-17</a></p>
                    <p class="black-link"><a href="mailto:ooobts2021@yandex.ru"><span class="mdi mdi-email"></span>ooobts2021@yandex.ru</a></p>
                    <p class="black-link"><span class="mdi mdi-map-marker-outline"></span>Ленинградская область,  г. Гатчина, ул. К. Маркса, д. 16А, офис 3</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data(){
        return{
            links: [
                {txt: 'Главная', link: '/'},
                {txt: 'О компании', link: '/about'},
                {txt: 'Услуги', link: '/services'},
                {txt: 'Документы', link: '/docs'},
                {txt: 'Контакты', link: '/contacts'},
            ],
            servs: [
                {txt: 'Газификация юр. лиц', link: '/urlica'},
                {txt: 'Газификация физ. лиц', link: '/fizlica'},
                {txt: 'Газификация поселков', link: '/poselki'},
                {txt: 'Аренда газогенераторов', link: 'http://btsco.ru/', target: true},
                {txt: 'Котельные', link: '/cotelnie'},
            ],
        }
    }
}
</script>