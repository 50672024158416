<template>
    <div>
        <Roadmap />
        <Submit />
        <Gallery />
    </div>
</template>

<script>
import Roadmap from '../components/Roadmap.vue'
import Submit from '../components/Submit.vue'
import Gallery from '../components/Gallery.vue'

export default {
    components: {Roadmap, Submit, Gallery}
}
</script>