<template>
    <div>
        <Serv />
        <Submit />
    </div>
</template>

<script>
import Submit from '../components/Submit.vue'
import Serv from '../components/Serv.vue'

export default {
    components: {Serv, Submit}
}
</script>