<template>
  <div>

    <section id="main">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 wow fadeInUp">
            <h1>Газовая компания <br> <span class="blue">БТС</span></h1>
            <p class="big-black">Газификация коммерческих объектов 
            и частного жилья в Ленинградской 
            области</p>
            <button class="main-btn">Подробнее</button>
          </div>
          <div class="col-lg-6">
            <img src="../assets/img/guys.png" class="wow fadeInUp" data-wow-delay=".5s">
          </div>
        </div>
      </div>
    </section>


    <Calc />
    <Serv />

    <section id="advantages">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2>Преимущества ООО «БТС» <br>
            на рынке газификации::</h2>
          </div>

          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-6 wow fadeInUp" v-for="(item, index) in icons" :key="index">
                <div class="icon-box">
                  <div class="icon-circle">
                    <img :src="item.icon" alt="">
                  </div>
                  <h3>{{item.name}}</h3>
                  <p class="grey-txt">
                    {{item.txt}}
                  </p>
                </div>
              </div>
              <div class="col-lg-12 wow fadeInUp">
                <button class="main-btn">Оставить заявку</button>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
              <img src="../assets/img/guy.png" class="guy">
          </div>
        </div>
      </div>
    </section>

    <Gallery />
    <Submit />
    <Documents />
    
  </div>
</template>

<script>
import Calc from '../components/Calc.vue'
import Serv from '../components/Serv.vue'
import Gallery from '../components/Gallery.vue'
import Submit from '../components/Submit.vue'
import Documents from '../components/Documents.vue'

export default {
  components: {Calc,Serv,Gallery, Submit, Documents},
  data(){
    return{
      icons: [
        {
          icon: require('../assets/img/ic1.svg'), 
          name: 'Большой опыт работы',
          txt: 'Наши специалисты имеют опыт в десятки лет, а компания занимается строительством газопроводов и подключением домов с 2017 года.'
        },
        {
          icon: require('../assets/img/ic2.svg'), 
          name: 'Экономия времени заказчика',
          txt: 'Отлаженная схема работы позволяет нам гарантировать, что с момента обращения в компанию БТС до подачи газа в дом Вам не придется даже покидать своего участка, а Ваш персональный менеджер в любой момент времени сообщит на каком этапе находится проект.'
        },
        {
          icon: require('../assets/img/ic3.svg'), 
          name: 'Конкурентная стоимость выполнения работ в удобные сроки для заказчика.',
          txt: 'олитика нашей компании – предоставить заказчику самую низкую стоимость в самые короткие сроки, мы дорожим своей репутацией и никогда не нарушаем условий договора.'
        },
        {
          icon: require('../assets/img/ic4.svg'), 
          name: 'Юридическая поддержка',
          txt: 'В случае возникновения конфликтных или юридически сложных ситуаций в сфере подключения к газу, мы готовы Вам помочь. Мы обладаем большим опытом разрешения спорных ситуаций в кротчайшие сроки.'
        },
      ]
    }
  }
}
</script>
