<template>
    <section id="submit">

        <div class="popup" v-if="popup" @click="popup = false">
            <div class="calc-box" @click.stop>
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h3>Оставьте заявку, наши менеджеры <br> свяжутся с Вами</h3>
                    </div>
                    <div class="col-lg-12">
                        <input type="text" placeholder="Ваше имя">
                    </div>
                    <div class="col-lg-12">
                        <input type="text" placeholder="Ваш телефон">
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                        <label class="form-check-label" for="inlineCheckbox2">Я даю согласие на обработку
                                персональных данных</label>
                    </div>
                </div>
                <br><br>
                <div class="row">
                    <div class="col-lg-12">
                        <button class="main-btn" style="width:100%;">Оставить заявку</button>
                    </div>
                </div>
            </div>
        </div>



        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-lg-offset-2 text-center wow fadeInUp">
                    <h1>Остались вопросы?</h1>
                    <p class="big-white">Оставьте заявку или свяжитесь <br> с нашими менеджарами</p>
                    <div class="flex-btns">
                        <button class="white-btn" @click="popup = true">Оставить заявку</button>
                        <a href="tel:88126141317">
                            <button class="white-btn">8 (812) 614-13-17</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
            popup: false
        }
    }
}
</script>