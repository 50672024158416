<template>
    <div>
       <Documents />
        <Submit />
    </div>
</template>

<script>
import Submit from '../components/Submit.vue'
import Documents from '../components/Documents.vue'

export default {
    components: {Documents, Submit}
}
</script>