<template>
    <div>
        <section id="serv">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Контакты</h2>
                </div>
                <div class="col-lg-4 wow fadeIn">
                    <div class="serv-box">            
                        <div class="serv-body">
                            <h3>Общие контактные данные</h3>
                            <p class="black-link"><a href="tel:88126141317"><span class="mdi mdi-phone"></span>8 (812) 614-13-17</a></p>
                            <p class="black-link"><a href="mailto:ooobts2021@yandex.ru"><span class="mdi mdi-email"></span>ooobts2021@yandex.ru</a></p>
                            <p class="black-link"><span class="mdi mdi-map-marker-outline"></span>Ленинградская область,  г. Гатчина, ул. К. Маркса, д. 16А, офис 3</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 wow fadeIn">
                    <div class="serv-box">            
                        <div class="serv-body">
                            <h3>Газификация</h3>
                            <p class="black-link"><a href="tel:89995142966"><span class="mdi mdi-phone"></span>8 (999) 514-29-66</a></p>
                            <p class="black-link"><a href="tel:89219995666"><span class="mdi mdi-phone"></span>8 (921) 999-56-66</a></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 wow fadeIn">
                    <div class="serv-box">            
                        <div class="serv-body">
                            <h3>Газогенераторы и котельные</h3>
                            <p class="black-link"><a href="tel:89219995666"><span class="mdi mdi-phone"></span>8 (921) 999-56-66</a></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <yandex-map  :coords="coordinates" :scroll-zoom="false" :controls="[]"  :zoom="14" >
                        <ymap-marker 
                            :coords="coordinates" :icon="markerIcon" :marker-id="markerIcon.id" >
                        234</ymap-marker>
                    </yandex-map>
                </div>
            </div>
        </div>
    </section>
    <Submit />
</div>
</template>

<script>
import Submit from '../components/Submit.vue'
export default {
    components: {Submit},
    data(){
        return{
            coordinates: [59.559658, 30.128021],
            markerIcon: {
                id:234,
      				layout: 'default#imageWithContent',
      			    imageHref: require('../assets/img/marker.svg'),
      			    imageSize: [55, 55],
      			    imageOffset: [0, 0],
      			},
        }
    }
}
</script>

<style scoped>
.serv-body{
    min-height: 219px;
}
.ymap-container{
    height: 550px;
}
</style>